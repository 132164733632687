import { AspectRatio, Box, Flex, Text } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfLink } from "src/components/base/wf-link";
import { PrimaryHeadline } from "src/components/common/module-headlines";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { ScrollSnap, ScrollSnapItem } from "src/components/common/scroll-snap";
import { Container } from "src/components/layout/container";
import { IImage, ILink } from "src/types/common";
import { EnumHorizontalAlignment } from "src/types/common-enum";

interface IPreviewAdvantagesItem {
    title: string;
    image: IImage;
    description?: string;
    link?: ILink;
}

const PreviewAdvantagesItem = ({ title, description, link, image }: IPreviewAdvantagesItem) => {
    const item = (
        <Box>
            <AspectRatio mb={2} w="100%" ratio={3 / 2}>
                <ResponsiveImage
                    borderRadius="8px"
                    w="100%"
                    h="auto"
                    srcSet={[
                        { queryParams: { w: 312, h: 208, fit: "crop" } },
                        { queryParams: { w: 315, h: 210, fit: "crop" } },
                        { queryParams: { w: 291, h: 194, fit: "crop" } },
                        { queryParams: { w: 358, h: 239, fit: "crop" } },
                    ]}
                    baseUrl={image.url}
                    alt={image.alt}
                />
            </AspectRatio>
            <Text as="h3" fontSize="xl" fontWeight="bold" lineHeight="tall" mb={1} _groupHover={{ textDecor: "underline" }}>
                {title}
            </Text>
            {description && <SimpleRichText text={description} mb={2} />}
        </Box>
    );

    return link ? (
        <WfLink display="contents" role="group" variant="unstyled" href={link.url} target={link.target}>
            {item}
        </WfLink>
    ) : (
        item
    );
};

export interface IPreviewAdvantagesModuleProps {
    title: string;
    titleAsH1: boolean;
    headerAlignment: EnumHorizontalAlignment;
    link?: ILink;
    items: IPreviewAdvantagesItem[];
    columnsDesktop?: number;
    enableHorizontalScrolling?: boolean;
}

export const PreviewAdvantagesModule = ({
    title,
    titleAsH1,
    headerAlignment,
    link,
    items,
    columnsDesktop = 4,
    enableHorizontalScrolling = false,
}: IPreviewAdvantagesModuleProps) => {
    const hasThreeItems = items.length === 3;
    const textAlign = headerAlignment === EnumHorizontalAlignment.Left ? "left" : "center";

    return (
        <Container>
            {title && (
                <PrimaryHeadline textAlign={textAlign} mb={[5, 5, 7]} titleAsH1={titleAsH1}>
                    {title}
                </PrimaryHeadline>
            )}
            <ScrollSnap
                gridColumnGap={[2, hasThreeItems ? 2 : 5, 3]}
                gridRowGap={[2, 3, 5]}
                overflowX={["auto", "visible"]}
                templateColumns={[
                    `repeat(${enableHorizontalScrolling ? items.length + 1 : 1}, auto)`,
                    `repeat(${hasThreeItems ? 3 : 2}, minmax(0, 1fr))`,
                    `repeat(${columnsDesktop}, minmax(0, 1fr))`,
                ]}
            >
                {items.map((itemProps, index) => (
                    <ScrollSnapItem key={index} w={[enableHorizontalScrolling ? "288px" : "auto", "auto"]}>
                        <PreviewAdvantagesItem {...itemProps} />
                    </ScrollSnapItem>
                ))}
            </ScrollSnap>

            {link && (
                <Flex direction={["row", "row", "row-reverse"]}>
                    <ArrowLink display="inline-block" mt={[2, 3, 4]} href={link.url} target={link.target} colorScheme="green">
                        {link.name}
                    </ArrowLink>
                </Flex>
            )}
        </Container>
    );
};
