import { useMemo } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfHeading } from "src/components/base/wf-heading";
import { ButtonCta } from "src/components/common/cta/button-cta";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { CONTAINER_PX, CONTAINER_W, ContainerHeader } from "src/components/layout/container";
import { AREA_TOP_MARGIN } from "src/consts/styles";
import { IImage } from "src/types/common";
import { IButtonCtaProps } from "src/types/cta";
import { HEADER_MODULE_DEFAULT_COLOR_SCHEME, HeaderModuleColorScheme } from "./color-scheme";

export interface IHeaderModuleProps {
    title: string;
    titleAsH2?: string;
    description?: string;
    cta?: IButtonCtaProps;
    image?: IImage;
    colorScheme?: HeaderModuleColorScheme;
}

const PADDING_Y_SM = 7;
const PADDING_Y_MD = 10;

export const HeaderModule = ({ title, description, cta, image, colorScheme = HEADER_MODULE_DEFAULT_COLOR_SCHEME, titleAsH2 }: IHeaderModuleProps) => {
    // Dev-Note: as this module will only be placed on the very top, the code is also optimized for this use case

    const isGrayScheme = colorScheme === "gray";
    const hasImage = Boolean(image);
    const hasPaddingBottom = isGrayScheme || hasImage;

    // On mobile, if the module has an image, we don't want to have a padding on the top
    const paddingTop = [hasImage ? 0 : AREA_TOP_MARGIN[0], PADDING_Y_SM, PADDING_Y_MD];

    const paddingBottom = [
        // On mobile, if the module has the gray scheme, we want to show a padding bottom (to gain some space below the CTA or description)
        isGrayScheme ? 3 : 0,
        // From tablet and higher, if the module has the white scheme and no image, we don't want to show a padding bottom, as else it would result in too much space below the module
        hasPaddingBottom ? PADDING_Y_SM : 0,
        hasPaddingBottom ? PADDING_Y_MD : 0,
    ];

    const textContainerPaddingValues = useMemo(
        () =>
            CONTAINER_W.map((containerWidth, index) => {
                const containerPadding = CONTAINER_PX[index] ? CONTAINER_PX[index] * 8 : 0;
                return `calc((100% - ${containerWidth})/2 + ${containerPadding}px)`;
            }),
        []
    );

    return (
        <ContainerHeader>
            <Flex direction={["column", "row-reverse"]} alignItems="stretch" justifyContent="space-between">
                <Box w={["100%", "40%"]} mb={[4, 0]}>
                    {image && (
                        <ResponsiveImage
                            objectFit="cover"
                            objectPosition="center"
                            height={["auto", "100%"]}
                            minH={[0, 400]}
                            width="100%"
                            maxWidth="none"
                            srcSet={[
                                { queryParams: { w: 375, h: 250, fit: "crop" } },
                                { queryParams: { w: 400 } },
                                { queryParams: { w: 500 } },
                                { queryParams: { w: 600 } },
                            ]}
                            baseUrl={image.url}
                            alt={image.alt}
                            loading="eager"
                        />
                    )}
                </Box>
                <Flex w={["100%", "60%"]} pr={[3, 4, 7, 10]} pl={textContainerPaddingValues} direction="column" justifyContent="center">
                    <Box pt={paddingTop} pb={paddingBottom}>
                        <WfHeading as="h1" fontSize={["4xl", "4xl", "5xl"]}>
                            {title}
                        </WfHeading>
                        {titleAsH2 && (
                            <WfHeading as="h2" fontSize={["xl", "2xl"]} mt={[2, 3]}>
                                {titleAsH2}
                            </WfHeading>
                        )}
                        {description && (
                            <SimpleRichText
                                text={description}
                                mt={titleAsH2 ? 1 : [3, 3, 4]}
                                fontSize={["md", "lg", "xl"]}
                                lineHeight={["base", "base", "tall"]}
                            />
                        )}
                        {cta && <ButtonCta {...cta} variant="solid" mt={4} w={["100%", "auto"]} />}
                    </Box>
                </Flex>
            </Flex>
        </ContainerHeader>
    );
};
