import { useEffect, useMemo, useState } from "react";
import { Flex, SimpleGrid } from "@chakra-ui/react";
import { AnimatePresence } from "motion/react";
import { MotionBox } from "src/components/base";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { IImage } from "src/types/common";
import { chunkArrayEqually } from "src/utils/array-util";

const START_INDEX = 0;

export interface IPartnerLogosModuleProps {
    title: string;
    logos: IImage[];
}
export const PartnerLogosModule = (props: IPartnerLogosModuleProps) => {
    const { title, logos } = props;
    const [chunkIndex, setChunkIndex] = useState(START_INDEX);
    const logoChunks = useMemo(() => chunkArrayEqually(logos, 6), [logos]);

    useEffect(() => {
        const intervalId = setInterval(() => setChunkIndex(index => (index + 1) % logoChunks.length), 6000);

        return () => clearInterval(intervalId);
    });

    return (
        <Container>
            <ModulePrimaryHeadline mb={[5, 7]} textAlign="center">
                {title}
            </ModulePrimaryHeadline>
            <Flex pos="relative" justify="center" h={["244px", "152px", "60px"]} overflow="hidden">
                <AnimatePresence initial={false}>
                    <MotionBox
                        key={chunkIndex}
                        pos="absolute"
                        initial={{ opacity: 0, x: 500 }}
                        animate={{ zIndex: 1, x: 0, opacity: 1 }}
                        exit={{
                            zIndex: 0,
                            x: -500,
                            opacity: 0,
                        }}
                        transition={{ bounce: 0, duration: 0.8 }}
                    >
                        <SimpleGrid columns={[2, 3, 6]} gap={[3, 4]} alignItems="center">
                            {logoChunks[chunkIndex].map((logo, idx) => (
                                <ResponsiveImage
                                    key={idx}
                                    w="120px"
                                    mx="auto"
                                    baseUrl={logo.url}
                                    alt={logo.alt}
                                    srcSet={[{ queryParams: { w: 120, h: 60 } }]}
                                />
                            ))}
                        </SimpleGrid>
                    </MotionBox>
                </AnimatePresence>
            </Flex>
        </Container>
    );
};
