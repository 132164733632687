import { HStack, Text } from "@chakra-ui/react";
import { ButtonCta } from "src/components/common/cta/button-cta";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { ICtaModuleProps } from "./cta-module.types";

export const CtaModule = ({ title, description, cta, secondCta }: Omit<ICtaModuleProps, "colorScheme">) => {
    const isImageButtonsOnly = cta.variant === "image" && secondCta?.variant === "image";
    const ctaWidth = isImageButtonsOnly ? "auto" : ["100%", "auto"];

    return (
        <Container>
            <ModulePrimaryHeadline mb={3}>{title}</ModulePrimaryHeadline>
            {description && (
                <Text mb={[3, 3, 4]} fontSize={["xl", "xl", "2xl"]} lineHeight={["tall", "tall", "base"]}>
                    {description}
                </Text>
            )}
            <HStack flexWrap={isImageButtonsOnly ? "nowrap" : ["wrap", "nowrap"]} gap={2}>
                <ButtonCta {...cta} w={ctaWidth} justifyContent={cta.variant === "image" ? "flex-start" : undefined} />
                {secondCta ? (
                    <ButtonCta {...secondCta} w={ctaWidth} justifyContent={secondCta.variant === "image" ? "flex-start" : undefined} />
                ) : undefined}
            </HStack>
        </Container>
    );
};
